import { useTranslation } from 'react-i18next';
import { Title, WithHeader } from 'components';
import { useAccountVM } from './useAccountVM';
import {
    AccountContainer,
    DisconnectButton,
    Separator,
    SizedSvyIcon,
    WaitingText,
} from './Account.style';

const Account: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const { userName, goBack, logout, isLogoutLoading } = useAccountVM();

    return (
        <>
            <WithHeader
                isSticky={true}
                headerContent={
                    <Title
                        data-testid="user-name"
                        type={5}
                        text={'account_page.hello'}
                        values={{ userName }}
                    />
                }
                onClickBack={goBack}
            >
                <AccountContainer>
                    <Title text="🚧" noTranslation type={1} />
                    <WaitingText type={2} text={'account_page.waiting_page'} />
                </AccountContainer>

                <Separator />

                <DisconnectButton
                    variant="text"
                    color="destructive"
                    size="m"
                    onClick={logout}
                    disabled={isLogoutLoading}
                    elementLeft={<SizedSvyIcon icon="logout-circle-r-line" />}
                    data-testid="logout-btn"
                >
                    {t('account_page.logout')}
                </DisconnectButton>
            </WithHeader>
        </>
    );
};

export default Account;
