import * as React from 'react';

export enum SVGIcons {
    APPLEPAY = 'applePay',
    BANKCARD = 'bankCard',
    CARRET = 'carret',
    CASHLINE = 'CashLine',
    CHECK = 'check',
    CHEVRON = 'chevron',
    DANGER = 'danger',
    EDENRED_COPY = 'edenred_copy',
    EDENRED = 'edenred',
    EMPTY = 'empty',
    LOCK = 'lock',
    MASTERCARD = 'masterCard',
    PAYGREEN = 'paygreen',
    SIT_IN = 'sit_in',
    SSL = 'ssl',
    TAKE_AWAY = 'take_away',
    VISA = 'visa',
}

export type SVGIconProps = {
    icon: SVGIcons;
    size?: number;
    testid?: string;
    rotate?: number;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
};
