import { useAuthenticate } from 'hooks/useAuthenticate/useAuthenticate';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { getUserName } from 'redux/user/user.slice';

export const useAccountVM = () => {
    const userName = useAppSelector(getUserName);

    const { logout, isLogoutLoading } = useAuthenticate();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return {
        userName: userName ?? '',
        goBack: handleGoBack,
        logout,
        isLogoutLoading,
    };
};
