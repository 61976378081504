export type Env = {
    APP_ENV: AppEnv;
    APP_VERSION: string;
    APP_IN_MEMORY?: boolean;
    PAYMENT_SERVICE_URL: string;
    STRIPE_PUBLIC_KEY: string;
    SENTRY_DSN: string;
    API_URL: string;
    FIRESTORE_API_KEY: string;
    FIRESTORE_AUTH_DOMAIN: string;
    FIRESTORE_PROJECT_ID: string;
    FIRESTORE_STORAGE_BUCKET: string;
    FIRESTORE_MESSAGING_SENDER_ID: string;
    FIRESTORE_APP_ID: string;
    ELASTIC_APM_ACTIVE?: boolean;
    ELASTIC_APM_ENVIRONMENT?: string;
    ELASTIC_APM_SERVICE_NAME?: string;
    ELASTIC_APM_SERVER_URL?: string;
    LAUNCH_DARKLY_CLIENT_ID: string;
};

export enum AppEnv {
    Local = 'local',
    Dev = 'dev',
    Preprod = 'preprod',
    Production = 'production',
}
