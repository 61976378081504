import { AppEnv, Env } from './types';
import { enumValues, throwIfFalsy } from './typescript';

const appEnv = process.env.REACT_APP_ENV;

throwIfFalsy(appEnv, `'REACT_APP_ENV' variable must be set`);
throwIfFalsy(process.env.REACT_APP_VERSION, `'REACT_APP_VERSION' variable must be set`);
throwIfFalsy(process.env.REACT_APP_API_URL, `'REACT_APP_API_URL' variable must be set`);
throwIfFalsy(
    process.env.REACT_APP_PAYMENT_SERVICE_URL,
    `'REACT_APP_PAYMENT_SERVICE_URL' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    `'REACT_APP_STRIPE_PUBLIC_KEY' variable must be set`,
);
throwIfFalsy(enumValues(AppEnv).includes(appEnv), `APP_ENV=${appEnv} is not allowed`);
throwIfFalsy(
    process.env.REACT_APP_ENV_SENTRY_DSN,
    `'REACT_APP_ENV_SENTRY_DSN' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    `'REACT_APP_LAUNCH_DARKLY_CLIENT_ID' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_PAYMENT_SERVICE_URL,
    `'REACT_APP_PAYMENT_SERVICE_URL' variable must be set`,
);

throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_API_KEY,
    `'REACT_APP_FIRESTORE_API_KEY' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
    `'REACT_APP_FIRESTORE_AUTH_DOMAIN' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_PROJECT_ID,
    `'REACT_APP_FIRESTORE_PROJECT_ID' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
    `'REACT_APP_FIRESTORE_STORAGE_BUCKET' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
    `'REACT_APP_FIRESTORE_MESSAGING_SENDER_ID' variable must be set`,
);
throwIfFalsy(
    process.env.REACT_APP_FIRESTORE_APP_ID,
    `'REACT_APP_FIRESTORE_APP_ID' variable must be set`,
);

export const ENV: Env = {
    APP_ENV: appEnv as AppEnv,
    APP_VERSION: process.env.REACT_APP_VERSION,
    APP_IN_MEMORY: process.env.REACT_APP_IN_MEMORY === 'true',
    API_URL: process.env.REACT_APP_API_URL,
    PAYMENT_SERVICE_URL: process.env.REACT_APP_PAYMENT_SERVICE_URL,
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    SENTRY_DSN: process.env.REACT_APP_ENV_SENTRY_DSN,
    ELASTIC_APM_ACTIVE: process.env.REACT_APP_ELASTIC_APM_ACTIVE === 'true',
    ELASTIC_APM_ENVIRONMENT: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
    ELASTIC_APM_SERVICE_NAME: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
    ELASTIC_APM_SERVER_URL: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
    LAUNCH_DARKLY_CLIENT_ID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    FIRESTORE_API_KEY: process.env.REACT_APP_FIRESTORE_API_KEY,
    FIRESTORE_AUTH_DOMAIN: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
    FIRESTORE_PROJECT_ID: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
    FIRESTORE_STORAGE_BUCKET: process.env.REACT_APP_FIRESTORE_STORAGE_BUCKET,
    FIRESTORE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
    FIRESTORE_APP_ID: process.env.REACT_APP_FIRESTORE_APP_ID,
};
