import { TranslationKey } from 'locales/fr';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getBrandId } from 'redux/brand';
import { getAccessToken, resetUser, updateUser } from 'redux/user/user.slice';
import {
    useLoginMutation,
    useLogoutMutation,
} from 'services/authentication/authentication.endpoint';
import { LoginErrorPayload } from 'services/authentication/authentication.type';
import { useWorkflowStateMachine } from 'hooks/useWorkflowStateMachine';
import { updateAppState } from 'redux/app';
import { useAppDispatch } from 'redux/store';
import { computeLoginErrorMessage } from './useAuthenticate.utils';

export const useAuthenticate = () => {
    const brandId = useSelector(getBrandId);
    const token = useSelector(getAccessToken);

    const [error, setError] = useState<TranslationKey | undefined>();

    const { goNext, goHome } = useWorkflowStateMachine();
    const dispatch = useAppDispatch();
    const [loginQuery, { isLoading: isLoginLoading, error: loginError }] = useLoginMutation();
    const [logoutQuery, { isLoading: isLogoutLoading, error: isLogoutError }] = useLogoutMutation();

    const handleLogin = async (credentials: { username: string; password: string }) => {
        if (!brandId) return;

        if (token) {
            goNext();
            return;
        }

        setError(undefined);

        const loginResult = await loginQuery({
            ...credentials,
            brandId,
        });

        if ('error' in loginResult) {
            const {
                data: { code },
            } = loginResult.error as LoginErrorPayload;

            setError(computeLoginErrorMessage(code));
            return;
        }

        const { accessToken, customer } = loginResult.data;

        dispatch(
            updateUser({
                accessToken,
                customerId: customer.customerId,
                email: customer.email,
                phone: customer.phone,
                userName: `${customer.firstName} ${customer.lastName}`,
            }),
        );

        goNext();
    };

    const handleLogout = async () => {
        if (!token) return;
        await logoutQuery({
            token,
        });

        dispatch(resetUser(undefined));
        dispatch(updateAppState({ shouldDisplayLogoutPopup: true }));
        goHome();
    };

    const resetError = () => {
        setError(undefined);
    };

    return {
        login: handleLogin,
        isLoginLoading,
        loginError,
        error,
        resetError,
        logout: handleLogout,
        isLogoutLoading,
        isLogoutError,
    };
};
